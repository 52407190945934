import ajax from 'axios'
// import axios from 'axios';
// import { getStorages } from '@/Utils/localStorage'
import store from '@/store'
import { setToken, setEquipmentNo, removeToken, removeEquipmentNo } from '@/utils/cookie'
import Vue from 'vue';
import { Toast } from 'vant';
import { mapActions, mapGetters } from 'vuex';

Vue.use(Toast);

const axios = ajax.create({
  // baseURL: process.env.NODE_ENV === "production" ? "http://api.inclusivekredit.com:20222" : "/", // 测试环境
  // baseURL: 'http://218.75.109.186:22222', //
  // baseURL: '/api', //
  baseURL: '/api',
  // baseURL: 'https://gkdlo.money-plus-app.in/',
  //https://gkdlo.money-plus-app.in/
  timeout: 30000,//
  withCredentials: true,
  store: store
})

// async function init() {
//   await this.list1();
//   this.list2();
// },

//
axios.interceptors.request.use(
  async function (config) {
    const store = config.store;
    console.log('store', store);

    if (store) {
      if (!store.getters.getFlutterBaseInfo.token) {
        await store.dispatch('fetchFlutterBaseInfo');
      }

      const flutterBaseInfo = store.getters.getFlutterBaseInfo;
      console.log('flutterBaseInfo', flutterBaseInfo, config);

      if (flutterBaseInfo) {
        setToken(flutterBaseInfo.token);
        config.data = { ...flutterBaseInfo, ...config.data };
        config.headers['Content-Type'] = 'application/json';
        const token = flutterBaseInfo.token;
        config.headers['Authorization'] = `Bearer ${token}`;
        console.log(config.headers, ')))))))))))111111111111');
      }
    }

    const baseInfo = JSON.parse(localStorage.getItem('BaseInfo'));
    if (baseInfo && baseInfo.token) {
      console.log(config.headers, '++++++++++++++');
      return config;
    }

    // 如果 baseInfo 还未准备好，等待直到准备好
    return new Promise((resolve) => {
      const checkToken = setInterval(() => {
        const baseInfo = JSON.parse(localStorage.getItem('BaseInfo'));
        if (baseInfo && baseInfo.token) {
          clearInterval(checkToken);
          resolve(config);
        }
      }, 100);
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  if (response.data.code === 1000) {
    window.android.loginOut();
  }
  if (response.data.code === 9999) {
    Toast(response.data.msg)
  }


  // const data = window.baseAndroid.parameterDecryption(response.data.mkljdjkmdklw34gh)

  // let res = JSON.parse(data)
  // console.log(49, res);
  // console.log('request', res, res['3B373C3D'], res['3C392C39']);
  // let res = data1['3C392C39']
  // if (res['3B373C3D'] == 1000) {
  // 	removeToken()
  // 	removeEquipmentNo()
  // 	window.baseAndroid.logout()//code===1000
  // 	return Promise.reject(res['3C392C39']).catch(() => { })
  // } else if (res['3B373C3D'] === 0) {
  // 	// Toast(res['352B3F']);
  // 	if (Object.prototype.toString.call(res['3C392C39']) === '[object Object]') {
  // 		res['3C392C39'].msg = res['352B3F']
  // 	}
  // 	return res['3C392C39']
  // } else if (res['3B373C3D'] === 100) {

  // } else {
  // 	Toast(res['352B3F']);
  return response.data

  // }
}, function (error) {
  return Promise.reject(error)
})

export default ({ url, method = 'GET', data = {}, params = {} }) => {
  return axios({
    url,
    method,
    data,
    params
  })
}
// export default axios
