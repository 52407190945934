let nativeBridgeConfig = {
  environment: null,
  nativeCall: null
};
let iosData = null;
let pendingRequests = {};
function initNativeBridge() {
  if (typeof window.flutter_inappwebview !== 'undefined') {
    nativeBridgeConfig.environment = 'Flutter InAppWebView';
    nativeBridgeConfig.nativeCall = async function (methodName, ...args) {
      try {
        let result;
        try {
          result = await window.flutter_inappwebview.callHandler(methodName, ...args);
        } catch (error) {
          result = 'no';
        }
        console.log(window.flutter_inappwebview, result, 'flutter_inappwebview333333333333');
        return Promise.resolve(JSON.stringify(result));

      } catch (error) {
        return Promise.reject(error);
      }
    };
  } else if (typeof window.android !== 'undefined') {
    console.log('android');
    nativeBridgeConfig.environment = 'Native Android';
    nativeBridgeConfig.nativeCall = function (methodName, ...args) {
      try {
        const result = window.android[methodName]
          ? window.android[methodName](...args)
          : 'no';
        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    };
  } else if (typeof window.webkit !== 'undefined' && window.webkit.messageHandlers) {
    // alert('iOS');
    console.log('iOS');
    nativeBridgeConfig.environment = 'iOS';
    window.shareResult = shareResult;


    nativeBridgeConfig.nativeCall = function (methodName, ...args) {

      const key = localStorage.getItem('iosKey');//zheli
      console.log('key:', key)
      if (key) {
        const encryptedText = xorEncryptDecrypt(methodName, key);
        console.log("Encrypted:", encryptedText, key, xorEncryptDecrypt(encryptedText, key));

        return new Promise((resolve, reject) => {
          try {
            window.webkit.messageHandlers[encryptedText].postMessage({ args });
            const checkInterval = setInterval(() => {
              if (iosData != null) {
                clearInterval(checkInterval);
                if (iosData !== undefined) {
                  return resolve(iosData);
                } else {
                  reject(new Error('Invalid data received'));
                }
              }
            }, 100);
            const timeout = setTimeout(() => {
              clearInterval(checkInterval);
              reject(new Error('Request timed out'));
            }, 5000);
            return () => {
              clearTimeout(timeout);
            };
          } catch (error) {
            return reject(error);
          }
        });
      };
    }

  } else {
    nativeBridgeConfig.environment = 'Unknown';
    nativeBridgeConfig.nativeCall = () => {
      throw new Error('Unsupported environment for native calls.');
    };
  }
}
function generateRequestId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
function xorEncryptDecrypt(input, key) {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    output += String.fromCharCode(input.charCodeAt(i) ^ key);
  }
  return output;
}
function shareResult(strings) {
  iosData = strings;
}
export { initNativeBridge, nativeBridgeConfig };
