import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('@/views/loan/index'),
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/views/coupons/index'),
  },
  {
    path: '/cuponDetails',
    name: 'cuponDetails',
    component: () => import('@/views/cuponDetails/index'),
  },
  {
    path: '/iosLoan',
    name: 'iosLoan',
    component: () => import('@/views/iosLoan/index'),
  },
  {
    path: '/andReceiving',
    name: 'andReceiving',
    component: () => import('@/views/andReceiving/index'),
  },
  {
    path: '/delete',
    name: 'delete',
    component: () => import('@/views/delete/index'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/index'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/index'),
  },
  {
    path: '/ReceivingList',
    name: 'ReceivingList',
    component: () => import('@/views/ReceivingList/index'),
  },
  {
    path: '/loanHistory',
    name: 'loanHistory',
    component: () => import('@/views/loanHistory/index'),
  },
  {
    path: '/loanDetails',
    name: 'loanDetails',
    component: () => import('@/views/loanDetails/index'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),

  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('@/views/certificate/index'),

  },
  {
    path: '/preRiskControl',
    name: 'preRiskControl',
    component: () => import('@/views/preRiskControl/index'),

  },
  {
    path: '/extend',
    name: 'extend',
    component: () => import('@/views/extend/index'),

  },
  {
    path: '/repayment',
    name: 'repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/repaymentWay',
    name: 'repaymentWay',
    component: () => import('@/views/repaymentWay/index'),
  },
  {
    path: '/repaymentDetails',
    name: 'repaymentDetails',
    component: () => import('@/views/repaymentDetails/index'),
  },
  {
    path: '/yourself',
    name: 'yourself',
    component: () => import('@/views/yourself/index'),
  },
  {
    path: '/Receiving',
    name: 'Receiving',
    component: () => import('@/views/Receiving/index'),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let appId = localStorage.getItem("appCode")
  document.title = `${appId}-${to.name}`
  const pageName = to.name;
  window.gtag("event", "page_view", {
    page_title: pageName,
  });
  const enterTime = Date.now();
  to.meta.leaveGuard = router.afterEach((to, from) => {
    const stayTime = Date.now() - enterTime;
    window.gtag("event", "stay_time", {
      value: stayTime,
      event_category: "timing",
      event_label: pageName,
    });
    to.meta.leaveGuard();
  });
  next()

})
export default router
